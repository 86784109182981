html, body, #map {
  height: 100vh;
  width: 100%;
  font-family: "Avenir", "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.App {
  text-align: center;
}

.App.RTL {
  direction: rtl;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}

.leaflet-control-attribution {
  opacity: 0.3;
}

/* Bottom of zoom cropped on some mobile browsers */
.leaflet-control-container .leaflet-bottom .leaflet-control-zoom.leaflet-control {
  position: absolute;
  margin: 0;
  /* margin-bottom: 35px; */
  left: 17px;
  bottom: 17px;
}

.leaflet-popup-content-wrapper {
  max-height: 400px;
  overflow-y: scroll;
}

.SiteHeader header {
  display: block;
  position: absolute;
  background: white;
  border-radius: 5px;
  text-align: center;
  margin-left: -37vw;
  margin-top: 0px;
  width: 74vw;
  height: 30px;
  line-height: 30px;
  top: 8px;
  left: 50%;
  color: black;
  text-transform: uppercase;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.SiteHeader.Dark header {
  color: white;
  background: none;
  box-shadow: none;
}

@media only screen and (min-width: 600px) {
  .SiteHeader header {
    width: 300px;      
    margin-left: -150px;
  }
}

.SiteHeader header .logo {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.LanguageSelector {
  margin-left: 10px;
}

.LanguageSelector .lang {
  font-size: 12px;
  font-weight: normal;
  margin-right: 2px;
  padding: 0 3px;
  color: #CCC;
  text-transform: uppercase;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
}

.LanguageSelector .lang.sel {
  color: black;
}

.SiteHeader.Dark .LanguageSelector .lang {
  color: #999;
}

.SiteHeader.Dark .LanguageSelector .lang.sel {
  color: white;
}

.SiteHeader header h1 {
  display: inline-block;
  margin: 0;
  padding: 0;
  line-height: 30px;
  font-size: 12px;
}

.SiteHeader .MenuIcon {
  position: absolute;
  top: 1.7vh;
  left: 1.5vh;
  width: 25px;
  height: 25px;
  z-index: 1000;
  cursor: pointer;
}

.SiteHeader.Dark .MenuIcon {
  filter: invert(100%);
}

.SummaryTable {
  display: block;
  position: absolute;
  background: #ffffff;
  /* background: rgba(0, 0, 0, 0.15); */
  border-radius: 5px;
  text-align: center;
  width: 270px;
  height: 85px;
  bottom: 17px;
  left: 50%;
  margin-left: -135px;
  padding: 10px;
  color: black;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  border: 1px solid rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.SummaryTable:hover {
  border: 1px solid rgba(0, 0, 0, 1.0)
}

.SummaryTable .MainLabel {
  text-transform: uppercase;
  margin-bottom: 10px;
}

.SummaryTable .LinkText {
  display: block;
  margin-top: 5px;
  text-align: right;
}

.SummaryTable #summaryDateSpan {
  color: black;
}

.SummaryTable .LinkText img {
  width: 10px;
  height: 10px;
}

.SummaryTable .LinkText a {
  color: gray;
  font-size: 0.6em;
  text-decoration: underline;
}

.SummaryTable .LinkText a:hover {
  color: black;
}

.SummaryTable .attributeColumn {
  display: block;
  float: left;
  margin: 0;
  padding: 0;
}

.SummaryTable .attributeColumn > .attributeLabel {
  text-align: center;
  line-height: 0.3em;
  font-size: 0.6em;
}

.SummaryTable .attributeColumn > .attributeValue {
  display: block;
  font-size: 1.2em;
  line-height: 0.6em;
  font-weight: bold;
}

.ScrubTimeline {
  display: block;
  position: absolute;
  width: 40px;  
  right: 10px;
  top: 60px;
  bottom: 140px;
  z-index: 500;
}

.ScrubTimeline.WalkthroughOpen {
  top: 215px;
}


@media only screen and (min-width: 600px) {
  .ScrubTimeline.WalkthroughOpen {
    top: 315px;
  }
}

.ScrubTimeline .Scrubber {
  display: block;
  position: absolute;
  width: 40px;  
  top: 50px;
  bottom: 50px;
  touch-action: none;
}

.ScrubTimeline .Reset {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
  background-color: white;
  padding: 5px;
  user-select: none;
}

.ScrubTimeline .ShiftButtons {
  display: block;
  position: absolute;
  cursor: pointer;
  direction: ltr;
  width: 60px;
  left: -18px;
  height: 30px;
  z-index: 601;
  bottom: 0px;
  background-color: white;  
  border-radius: 15px;  
}

.ScrubTimeline .ShiftButtons a {
  display: inline-block;
  margin: 5px 0 0 0;
  padding: 0;
}

.ScrubTimeline .ShiftButtons a.Prev {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.ScrubTimeline .ShiftButtons a.Next {
  margin-left: 5px;
}

.ScrubTimeline .Reset img {
  width: 20px;
  height: 20px;
}


.ScrubTimelineRel {
  position: relative;
  height: 100%;
  touch-action: none;
}

.ScrubTimeline .Bar {
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 13px;
  width: 1px;
  background-color: #777;
  border-radius: 2px;
  touch-action: none;
}

.ScrubTimelineRel .Marker {
  position: absolute;
  display: block;
  z-index: 510;
  width: 30px;
  height: 30px;
  opacity: 0.5;
  left: 14px;
  border-radius: 15px;
  background-color: red;
  z-index: 510;
  touch-action: none;
  user-select: none;
}

.ScrubTimelineRel .Marker.Sel {
  opacity: 1.0;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.3);
}

.ScrubTimelineRel .DateLabel {
  position: absolute;
  display: block;
  color: white;
  font-size: 1.1em;
  font-weight: bold;;
  text-align: right;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 1.0);
  width: 300px;
  right: 60px;
  height: 20px;
  line-height: 20px;
  margin-top: -10px;
  user-select: none;
}

.ModalBG {
  display: block;
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1999;
}

.ModalOverlay {
  position: absolute;
  display: block;
  background-color: #ffffff;
  border-radius: 5px;
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  z-index: 2000;
}


@media only screen and (min-width: 600px) {
  .ModalOverlay {
    top: 150px;
    bottom: 150px;
    left: 250px;
    right: 250px;
    z-index: 2000;
  }

  .ModalOverlay .Content {
    font-size: 1.2em;
    padding: 30px;
  }
}

.ModalOverlay h1 {
  margin-top: 35px;
  font-size: 1.2em;
}

.ModalOverlay .Content {
  display: block;
  position: absolute;
  top: 90px;
  margin: 0;
  bottom: 10px;
  right: 10px;
  left: 10px;
  overflow-y: scroll;
  font-size: 1.1em;
}

.ModalOverlay .CloseIcon {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: block;
  cursor: pointer;
}

.ModalMenu {
  display: block;
  background-color: white;
  text-align: center;
}

.ModalMenu ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  border-top: 1px solid #DDD;
}

.ModalMenu ul li {
  line-height: 50px;
  border-bottom: 1px solid #DDD;
}

.ModalMenu ul li.Current {
  background-color: #EFEFEF;
}

.ModalMenu ul a {
  display: block;
  color: black;
  text-decoration: none;;
}

.ModalMenu ul a:hover {
  background-color: #DDD;
}

.EventDetail h2 {
  font-size: 1.0em;
}

.EventDetail ul {
  list-style-type: none;
  padding: 0px;
}

.EventDetail li.Participation {
  padding: 5px 8px;
  background-color: rgb(255, 251, 231);
  border: 1px solid rgb(246, 236, 189);;
  border-radius: 10px;
}

.EventDetail.WithDivider {
  border-top: 1px solid #DDD;
}

.MarkerCluster {
  background: #ffffff;
  border: 15px solid #f00800;
  border-radius: 50%;
  color: #000000;
  height: 33px;
  line-height: 37px;
  text-align: center;
  width: 33px;
}

.MarkerCluster.Low {
  border: 15px solid #5B94C8;
}

.MarkerCluster.Mid {
  border: 15px solid #275E91;
}

.MarkerCluster.High {
  border: 15px solid #1C3A56;
}

.Introduction {
  text-align: justify;
}

.Introduction .Logo {
  display: block;
  width: 100%;
  margin: 0 auto;
}

@media only screen and (min-width: 600px) {
  .Introduction .Logo {
    max-width: 300px;
  }
}

.Introduction h2 {
  text-align: center;
}

.Introduction .Actions {
  text-align: center;
}

.Walkthrough {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  height: 200px;
  background: rgba(0, 0, 0, 0.6);
  padding: 0px;
  background-blend-mode: darken;
  background-repeat: no-repeat;
  z-index: 500;
  color: white;
}

@media only screen and (min-width: 600px) {
  .Walkthrough {
    height: 300px;
  }
}

.Walkthrough a.MoreButton:link, 
.Walkthrough a.MoreButton:visited,
.Walkthrough a.MoreButton:active
{
  text-transform: uppercase;
  text-decoration: none;
  border: 1px solid white;
  border-radius: 4px;
  font-size: 0.7em;
  padding: 2px 4px;
  color: white;
}

.Walkthrough .CloseButton {
  position: absolute;
  top: 191px;
  left: 50%;
  width: 50px;
  margin-left: -25px;
  text-decoration: none;
  text-transform: uppercase;
  background-color: #000000;
  border: none;
  border-radius: 4px;
  font-size: 0.7em;
  padding: 2px 4px;
  color: white;
  cursor: pointer;
}

@media only screen and (min-width: 600px) {
  .Walkthrough .CloseButton {
    top: 290px;
  }
}

.Walkthrough .WalkthroughHeader {
  margin-top: 45px;
  display: block;
  height: 70px;
  top: 50px;
}

.Walkthrough .Text {
  display: block;
  height: 75px;
  margin: 0px 0px;
  padding: 0px 8px;
  text-align: left;
  font-size: 0.9em;
  overflow-y: auto;
}

.App.RTL .Walkthrough .Text {
  text-align: right;
}

@media only screen and (min-width: 600px) {
  .Walkthrough .Text {
    font-size: 1.2em;
    margin: 5px auto;
    max-width: 500px;
    height: 160px;
  }
}

.WalkthroughHeader .ShiftButton {
  display: block;
  position: absolute;
  cursor: pointer;
  filter: invert(100%);
  width: 40px;
  height: 40px;
  margin-top: 5px;
  z-index: 601;
}

.WalkthroughHeader .ShiftButton.Next {
  right: 0px;
}

.WalkthroughHeader .ShiftButton.Last {
  transform: rotate(180deg);
  left: 0px;
}

.WalkthroughHeader .EventIndex {
  font-size: 0.6em;
  color: rgba(255, 255, 255, 0.5);
}

.WalkthroughHeader .Date {
  font-size: 1.5em;
  margin: 3px;
}

.WalkthroughHeader .Theme {
  font-size: 0.9em;
}

.WalkthroughHeader .Theme .ThemeValue {
  background-color: white;
  color: black;
  border-radius: 7px;
  padding: 0px 5px;
}


.Contribute form {
  padding: 10px;
  margin: 20px auto;
  background-color: #EFEFEF;
}

.Contribute form input, .Contribute form textarea {
  width: 90%;
  padding: 5px;
  margin-top: 3px;
}

button.StandardButton {
  display: block;
  margin: 5px auto;
  font-size: 16px;
  border-radius: 5px;
  padding: 10px 25px;
  background-color: #275E91;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  cursor: pointer;
}

.Contribute .Message {
  font-size: 1.4em;
  color: green;
}